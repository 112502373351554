import React from 'react'
import { img } from '@/tools/image'

interface Props {}

const TopFirstViewText: React.FC<Props> = () => (
  <>
    <h2>
      <img className="mb-3" src={img('text-welcome.png')} alt="ようこそ、さくっこへ！" />
    </h2>
    <p>
      家庭や学校とは別に安全に安心して落ち着いて過ごせる居場所として
      個々の担当指導員を中心に寄り添いながら日々の「育つ力」を助けていきます。
      就学児は学校課題や手作り工作等を、未就学児には手作り知育玩具等を使った療育を
      きめ細かなサービスにてサポートいたします。
    </p>
  </>
)

export default TopFirstViewText
