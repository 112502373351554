import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { SiteMetaTopFirstViewQuery } from '../../../types/graphql-types'
import TopFirstViewText from '@/components/top/TopFirstViewText'
import ScrollButtonToContent from '@/components/ScrollButtonToContent'
import { img } from '@/tools/image'
import Constants from '@/constants'

interface Props {}

const query = graphql`
  query SiteMetaTopFirstView {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const TopFirstView: React.FC<Props> = () => {
  const [contentOffsetY, setContentOffsetY] = React.useState(-1)
  const contentRef = React.useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (contentRef.current) {
      const contentOffset = contentRef.current.offsetTop
      setContentOffsetY(Math.ceil(contentOffset))
    } else {
      setContentOffsetY(-1)
    }
  }

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  })

  return (
    <StaticQuery
      query={query}
      render={(data: SiteMetaTopFirstViewQuery) => {
        const meta = data?.site?.siteMetadata
        return (
          <>
            <div className="mx-auto flex flex-wrap flex-col md:flex-row w-min-xl items-center">
              <div className="w-full lg:w-4/5 px-5 py-5 md:px-0 md:py-0 z-20">
                <div className="relative">
                  <img
                    className="w-full z-50 rounded-br-xl"
                    src={img('hero-cg.png')}
                    alt={`Welcome to ${meta?.title}`}
                  />
                  <img
                    className="absolute -mr-32 bottom-0 right-0 transform -rotate-30 hidden lg:block"
                    src={img('owl.png')}
                    srcSet={`${img('owl@2x.png')} 2x`}
                    alt="ふくろう"
                  />
                  <div className="absolute bottom-0 right-0 left-0">
                    <ScrollButtonToContent contentOffsetY={contentOffsetY} />
                  </div>
                </div>
                <div ref={contentRef} className="firstview-content my-10 relative">
                  <img
                    className="absolute top-0 right-0 -mr-48 hidden lg:block"
                    src={img('butterflies-yellow.png')}
                    srcSet={`${img('butterflies-yellow@2x.png')} 2x`}
                    alt="ちょうちょ"
                  />
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 sm:w-2/6 relative p-6 hidden lg:block">
                      <img
                        className="h-16 absolute top-0 right-0 mr-24"
                        src={img('logo.svg')}
                        alt={meta?.title || ''}
                      />
                      <div className="flex items-center absolute top-0 bottom-0 left-0 p-6">
                        <img
                          className="w-full"
                          style={{ maxWidth: '550px' }}
                          src={img('icons-firstview.png')}
                          alt="イメージ"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2 p-6">
                      <nav className="mb-10">
                        <ul className="flex justify-between w-full relative">
                          {Constants.MENUS_FIRST_VIEW.map((menu, index) => (
                            <li
                              className={classNames('flex justify-center w-1/4', index > 0 ? 'border-l' : '')}
                              key={index}
                            >
                              <Link to={menu.href}>
                                <img src={img(menu.src)} className="h-8 mx-auto mb-2" />
                                <span>{menu.text}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                      <div className="pl-6">
                        <TopFirstViewText />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/5 lg:w-1/5  p-8 self-stretch z-30 hidden lg:block">
                <img
                  className="m-auto md:w-42px sm:w-36px"
                  src={img('text-catchcopy.png')}
                  srcSet={`${img('text-catchcopy.png')} 2x`}
                  alt="ここは、アットホームな、第三の居場所"
                />
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default TopFirstView
