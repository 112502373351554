import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { img } from '@/tools/image'

interface Props {
  contentOffsetY: number
}

export const ScrollButtonToContent: React.FC<Props> = ({ contentOffsetY }) => {
  const scrollToContent = () => scroll.scrollTo(contentOffsetY - 16)
  const textStyle = {
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }

  return (
    <div className="text-center">
      <div className="inline">
        <span
          className="text-3xl text-white font-bold tracking-wider cursor-pointer"
          style={textStyle}
          onClick={() => scrollToContent()}
        >
          SCROLL
        </span>
        <img
          className="h-12 w-auto mb-4 mt-2 mx-auto cursor-pointer"
          src={img('icon-arrow-b@2x.png')}
          onClick={() => scrollToContent()}
        />
      </div>
    </div>
  )
}

export default ScrollButtonToContent
