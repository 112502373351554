import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'
import { SiteMetaIndexQuery } from '../../types/graphql-types'
import { img, instagram } from '@/tools/image'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import TopFirstView from '@/components/top/TopFirstView'
import TopFirstViewSP from '@/components/top/TopFirstViewSP'
import Schedule from '@/components/Schedule'
import DailyActivity from '@/components/DailyActivity'
import TelephoneLink from '@/components/TelephoneLink'

type Props = {
  data: SiteMetaIndexQuery
}

const query = graphql`
  query SiteMetaIndex {
    site {
      siteMetadata {
        title
        telephone
        address {
          addressRegion
        }
        instagramUsername
        serviceName
      }
    }
  }
`

const Index = () => (
  <StaticQuery
    query={query}
    render={(data: SiteMetaIndexQuery) => {
      const meta = data.site?.siteMetadata
      const areaName = meta?.address?.addressRegion
      const instagramLink = instagram(meta?.instagramUsername || '')
      return (
        <Layout
          hideNavMenu
          firstViewComponent={
            <>
              <BrowserView>
                <TopFirstView />
              </BrowserView>
              <MobileView>
                <TopFirstViewSP />
              </MobileView>
            </>
          }
        >
          <SEO title="" />

          {/* Greeting */}
          <div className="leading-normal md:leading-loose tracking-normal md:tracking-widest">
            <div className="flex flex-wrap">
              <div className="flex-initial md:flex-grow relative">
                <div className="relative md:absolute left-0 bottom-0">
                  <img className="w-auto mb-2 hidden md:block" src={img('pencil.png')} alt="えんぴつ" />
                  <h2 className="text-3xl font-bold text-center md:text-left mt-5 md:mt-0 mb-5 md:mb-0">
                    明石市の地域で親しまれています。
                  </h2>
                </div>
              </div>
              <div className="order-first md:order-last flex-initial mx-auto md:flex-shrink">
                <img className="w-auto" src={img('greeting.png')} alt="ごあいさつ" />
              </div>
            </div>
            <div className="mt-2 text-lg">
              <p className="mb-2">
                {meta?.serviceName}の事業所として開設しております「{meta?.title}」です。場所は{areaName}
                にあり、一戸建てのアットホームな事業所となっております。
              </p>
              <p className="mb-2">
                一人ひとり違いのあるお子さま方にゆっくりと関わりながら手作り工作や遊びを通し「育つ力」を見守りながら支援してまいります。
              </p>
              <p className="mb-2">
                また保護者の方々にも安心してお預け頂けるようスタッフ一丸となり取り組んでまいります。
              </p>
            </div>
          </div>

          {/* Schedule */}
          <div className="mt-10">
            <div className="flex mb-5 px-0 md:px-5 justify-center">
              <div className="hidden md:block flex-shrink relative">
                <img className="w-auto" src={img('tree1.png')} />
              </div>
              <div className="flex-grow mx-0 md:mx-2 relative">
                <div className="w-full relative md:absolute bottom-0 mb-1">
                  <h3 className="text-3xl font-bold text-center">ご見学、ご面談の予約もお電話にて承ります。</h3>
                </div>
              </div>
              <div className="hidden md:block flex-shrink relative">
                <img className="w-auto" src={img('tree2.png')} />
              </div>
            </div>
            <Schedule />
            <div className="md:flex md:px-5">
              <div>
                <p className="">入所受付はお気軽にお問い合わせください</p>
                <p className="">
                  <TelephoneLink />
                </p>
              </div>
              <p className="mt-4 ml-auto">
                <Link to="/entrance" className="inline-block bg-purple-300 text-white font-bold px-6 py-2">
                  <span className="">詳細はこちら ▶︎</span>
                </Link>
              </p>
            </div>
          </div>

          {/* Daily Activity */}
          <div className="bg-white mt-48 mb-6 md:mb-24 px-3 py-3 md:px-10 md:py-10 relative">
            <img
              className="h-56 relative md:absolute ml-auto mr-auto md:ml-0 md:mr-0 -mt-40 top-0 left-0"
              src={img('text-dailyactivity.png')}
              alt={`${meta?.title}の日々の活動`}
            />
            <img
              className="h-12 mx-auto hidden md:block relative md:absolute -mt-4 pl-12 top-0 right-0 left-0"
              src={img('bg-stationary.png')}
              alt="文房具"
            />
            <img
              className="h-56 relative md:absolute hidden md:block -mb-36 bottom-0 left-0"
              src={img('fox@2x.png')}
              alt="きつね"
            />
            <div className="flex flex-wrap mt-5 md:mt-6 mb-5 md:mb-8">
              <div className="w-full md:w-9/12">
                <h2 className="text-2xl md:text-xl font-bold text-center tracking-normal md:tracking-widest my-5 md:my-0">
                  わたしたちは、子育てを支援しています。
                </h2>
              </div>
              <div className="w-full md:w-3/12 mt-2 mb-4 md:mt-0 md:mb-0">
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                  <img className="mx-auto" src={img('instagram-button@2x.png')} alt="instagram" />
                </a>
              </div>
            </div>

            <div className="flex flex-wrap">
              <DailyActivity />
            </div>

            <div className="text-center md:text-right my-6 md:my-0">
              <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                <span className="bg-purple-300 text-white font-bold px-6 py-3 md:py-2">もっと見る ▶︎</span>
              </a>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default Index
