import React from 'react'
import DailyActivityThumbnail from '@/components/DailyActivityThumbnail'
import { DailyActivityQuery } from '../../types/graphql-types'
import { instagram } from '@/tools/image'
import { StaticQuery, graphql } from 'gatsby'

interface Props {}

const query = graphql`
  query DailyActivity {
    allInstaNode(limit: 6, sort: { fields: timestamp, order: DESC }) {
      edges {
        node {
          id
          preview
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const DailyActivity: React.FC<Props> = () => (
  <StaticQuery
    query={query}
    render={(data: DailyActivityQuery) => {
      const edges = data?.allInstaNode?.edges || []
      return (
        <div className="flex flex-wrap">
          {edges.map((edge, index) => (
            <div className="w-full sm:w-1/2 md:w-1/3 px-2 md:px-4 mb-8 md:mb-6" key={index}>
              <div className="mb-3 overflow-hidden">
                <DailyActivityThumbnail
                  href={instagram(`p/${edge?.node?.id}`)}
                  src={edge?.node?.localFile?.childImageSharp?.fluid?.src || ''}
                />
              </div>
            </div>
          ))}
        </div>
      )
    }}
  />
)

export default DailyActivity
