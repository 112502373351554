import React from 'react'
import TopFirstViewText from '@/components/top/TopFirstViewText'
import { img } from '@/tools/image'

interface Props {}

const TopFirstViewSP: React.FC<Props> = () => (
  <div className="mx-auto flex flex-wrap flex-col pt-12 items-center">
    <img className="w-full -z-50 mb-6" src={img('hero-cg.png')} />
    <div className="p-6">
      <TopFirstViewText />
    </div>
  </div>
)

export default TopFirstViewSP
