import React from 'react'

interface Props {
  href: string
  src: string
}

const DailyActivityThumbnail: React.FC<Props> = ({ href, src }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img className="w-auto mx-auto px-1 py-1 border border-purple-300" src={src} />
    </a>
  )
}

export default DailyActivityThumbnail
